<template>
  <section>
    <div v-if="!checkEmailTemplateExists()">Please Select Template</div>
    <div v-else>
      <div
        class="size-selector"
        v-if="this.currentEntity.viewType != 'HTML_CONTENT'"
      >
        <label for="cardHeight">Card Height:</label>
        <input
          type="range"
          id="cardHeight"
          min="100"
          max="700"
          v-model="cardHeight"
        />
        <span>{{ cardHeight }}px</span>
      </div>
      <el-row style="display: flex; justify-content: space-between">
        <el-col :md="20" :sm="24" :xs="24">
          <div class="filter-card" style="overflow-y: hidden">
            <div style="margin-left: 60px">
              <el-tooltip
                class="item"
                effect="dark"
                content="Previous page"
                placement="top-end"
              >
                <el-link
                  icon="el-icon-arrow-left"
                  :disabled="currentPage == 1"
                  :underline="false"
                  type="primary"
                  @click="applyPagination('previous')"
                ></el-link>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Next page"
                placement="top-end"
              >
                <el-link
                  icon="el-icon-arrow-right"
                  :disabled="currentPage * pageSize >= this.total"
                  :underline="false"
                  type="primary"
                  @click="applyPagination('next')"
                ></el-link>
              </el-tooltip>
            </div>
            <br />
          </div>
        </el-col>
        <el-col>
          <el-input
            placeholder="Search"
            size="small"
            v-model="searchQuery"
            clearable
            prefix-icon="el-icon-search"
            @input="performSearch"
            @focus="onInputFocus"
            style="width: 300px; margin-bottom: 20px"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row style="display: flex; flex-direction: row">
        <!-- First Block: Display all templates -->
        <el-col :span="4" style="overflow-y: hidden">
          <el-scrollbar wrap-style="min-height: 70vh">
            <div class="card-summary">
              <div
                v-for="(template, index) in !searchQuery
                  ? paginatedData
                  : this.searchData"
                :key="index"
                class="summary"
                @click="selectedCard(template)"
              >
                <div v-if="template.field === 'title'">
                  <span>Title:</span>{{ template.fieldValue }}
                </div>
                <div v-else-if="template.field === 'description'">
                  <span>Description:</span>{{ template.fieldValue }}
                </div>
                <div v-else>
                  <div
                    v-if="
                      getFieldKey('title') && getFieldValue('title', template)
                    "
                  >
                    <span class="bold-key">{{ getFieldKey("title") }} </span>:
                    <span class="card-data">{{
                      getFieldValue("title", template) | truncate(140, "...")
                    }}</span>
                  </div>
                  <div
                    v-if="
                      getFieldKey('description') &&
                      getFieldValue('description', template)
                    "
                  >
                    <span class="bold-key"
                      >{{ getFieldKey("description") }} </span
                    >:
                    <span class="card-data">{{
                      getFieldValue("description", template)
                        | truncate(140, "...")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-col>
        <!-- Second Block: Display selected  template details -->
        <el-col :span="6" style="max-height: 60vh">
          <div style="overflow-y: hidden">
            <el-scrollbar wrap-style="max-height: calc(100vh - 180px)">
              <div
                class="cards"
                v-if="
                  selectedRecord &&
                  (searchQuery != '' || middleDivFilterData.length)
                "
              >
                <div
                  v-for="(row, index) in searchQuery != ''
                    ? searchFilterData
                    : middleDivFilterData"
                  :key="index"
                  style="overflow-x: hidden"
                >
                  <div
                    class="entity-data-card"
                    v-if="
                      getProfilePicture(row) ||
                      getFieldValue('profile', row) ||
                      getFieldValue('title', row) ||
                      getFieldValue('description', row) ||
                      getFieldValue('field1', row) ||
                      getFieldValue('field2', row) ||
                      getFieldValue('field3', row)
                    "
                    @dblclick="cellDoubleClicked(row)"
                  >
                    <span
                      v-if="
                        isEditing.title ||
                        isEditing.description ||
                        isEditing.field1 ||
                        isEditing.field2 ||
                        isEditing.field3
                      "
                      class="edit-icons"
                    >
                      <i class="tick-icon" @click="doubleClickUpdate(row)"
                        >✔️</i
                      >
                      <i class="cross-icon" @click="cancelEditAll">❌</i>
                    </span>
                    <div class="card-profile">
                      <img
                        v-if="getProfilePicture(row)"
                        :src="getProfilePicture(row)"
                        style="border-radius: 50%"
                      />
                      <imgawai
                        v-if="getFieldValue('profile', row)"
                        :src="getFieldValue('profile', row)"
                        alt="icon"
                        style="border-radius: 50%"
                      />
                      <div v-else>
                        <img
                          v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                          src="@/assets/img/defaultAvatar/Individual.svg"
                          alt="icon"
                          style="border-radius: 50%"
                        />
                        <img
                          v-else
                          src="@/assets/img/defaultAvatar/Business.svg"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div class="card-body">
                      <p
                        v-if="
                          getFieldKey('title') && getFieldValue('title', row)
                        "
                        class="card-title"
                      >
                        <span class="bold-key">{{ getFieldKey("title") }} </span
                        >:
                        <span v-if="!isEditing.title" class="card-data">{{
                          getFieldValue("title", row) | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.title !== 'SELECT' &&
                              editableFields_Types.title !== 'DATE_TIME'
                            "
                            v-model="editableFields.title"
                            class="edit-input"
                          />
                        </span>

                        <el-select
                          v-if="
                            editableFields_Types.title === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.title"
                          size="small"
                        >
                          <el-option
                            v-for="option in options[getFieldKey('title')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.title === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>

                      <p
                        v-if="
                          getFieldKey('description') &&
                          getFieldValue('description', row)
                        "
                        class="card-description header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("description") }} </span
                        >:
                        <span v-if="!isEditing.description" class="card-data">{{
                          getFieldValue("description", row)
                            | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.description !== 'SELECT' &&
                              editableFields_Types.description !== 'DATE_TIME'
                            "
                            v-model="editableFields.description"
                            class="edit-input"
                          />
                        </span>
                        <el-date-picker
                          v-if="
                            editableFields_Types.description === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                        <el-select
                          v-if="
                            editableFields_Types.description === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.description"
                          size="small"
                        >
                          <el-option
                            v-for="option in options[
                              getFieldKey('description')
                            ]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                      </p>

                      <p
                        v-if="
                          getFieldKey('field1') && getFieldValue('field1', row)
                        "
                        class="card-description header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field1") }} </span
                        >:
                        <span v-if="!isEditing.field1" class="card-data">{{
                          getFieldValue("field1", row) | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.field1 !== 'SELECT' &&
                              editableFields_Types.field1 !== 'DATE_TIME'
                            "
                            v-model="editableFields.field1"
                            class="edit-input"
                          />
                        </span>
                        <el-select
                          v-if="
                            editableFields_Types.field1 === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.field1"
                          size="small"
                        >
                          <el-option
                            v-for="option in options[getFieldKey('field1')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.field1 === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>
                      <p
                        v-if="
                          getFieldKey('field2') && getFieldValue('field2', row)
                        "
                        class="card-description header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field2") }} </span
                        >:
                        <span v-if="!isEditing.field2" class="card-data">{{
                          getFieldValue("field2", row) | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.field2 !== 'SELECT' &&
                              editableFields_Types.field2 !== 'DATE_TIME'
                            "
                            v-model="editableFields.field2"
                            class="edit-input"
                          />
                        </span>
                        <el-select
                          v-if="
                            editableFields_Types.field2 === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.field2"
                          size="small"
                        >
                          <el-option
                            v-for="option in options[getFieldKey('field2')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.field2 === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>
                      <p
                        v-if="
                          getFieldKey('field3') && getFieldValue('field3', row)
                        "
                        class="card-description header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field3") }} </span
                        >:
                        <span v-if="!isEditing.field3" class="card-data">{{
                          getFieldValue("field3", row) | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.field3 !== 'SELECT' &&
                              editableFields_Types.field3 !== 'DATE_TIME'
                            "
                            v-model="editableFields.field3"
                            class="edit-input"
                          />
                        </span>
                        <el-select
                          v-if="
                            editableFields_Types.field3 === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.field3"
                          size="small"
                        >
                          <el-option
                            v-for="option in options[getFieldKey('field3')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.field3 === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>
                      <br />
                      <template
                        v-if="
                          showFields.action_group_button &&
                          showFields.action_group_button.length
                        "
                      >
                        <div
                          v-for="(
                            action, index
                          ) in showFields.action_group_button"
                          :key="index"
                        >
                          <template v-if="hasHash(action)">
                            <action-button-execute
                              style="margin-top: 10px"
                              :data="{
                                ...getActionField(action),
                                parent_entityDataId: row._id,
                              }"
                              :form="form"
                              :is-view="false"
                              :hideStyles="true"
                            ></action-button-execute>
                          </template>
                          <template v-else>
                            <a
                              v-if="
                                IframePermissions('VIEW', 'EntityGalleryView')
                              "
                              href="javascript:void(0);"
                              class="card-link"
                              @click="viewWorkUserData(row, action)"
                              style="
                                color: rgb(64, 158, 255);
                                text-decoration: none;
                              "
                            >
                              {{ action }}
                            </a>
                          </template>
                        </div>
                      </template>
                      <template
                        v-if="
                          !showFields.action_group_button ||
                          !showFields.action_group_button.length
                        "
                      >
                        <el-link
                          v-if="IframePermissions('VIEW', 'EntityGalleryView')"
                          type="primary"
                          :underline="false"
                          class="card-link"
                          style="margin-bottom: 10px"
                          @click="viewWorkUserData(row)"
                        >
                          View details
                        </el-link>
                      </template>
                    </div>
                  </div>
                  <el-empty
                    v-else
                    :image-size="100"
                    description="Not configured"
                    class="entity-data-card"
                  ></el-empty>
                </div>
              </div>
              <div v-else>
                <el-empty description="No data"></el-empty>
              </div>
              <div
                class="cards"
                v-if="
                  Array.isArray(searchFilterData) &&
                  searchFilterData.length === 0 &&
                  searchQuery
                "
              >
                <el-empty description="No data"></el-empty>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
        <!-- Third Block: Display selected email template content in full view -->
        <el-col style="width: 49vw; margin-right: 10px">
          <div
            v-for="(row, index) in searchQuery != ''
              ? searchFilterData
              : middleDivFilterData"
            :key="index"
            :span="14"
            style="
              max-height: 70vh;
              overflow-y: scroll;
              overflow-x: hidden;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              transition: transform 0.2s, box-shadow 0.2s;
              border: 1px solid slategray;
            "
            class="card-container p-1"
          >
            <el-scrollbar wrap-style="max-height: calc(100vh - 180px)">
              <div
                v-if="
                  actionButtonFieldKeys &&
                  actionButtonFieldKeys.length &&
                  isRelationalDataFetched
                "
              >
                <div
                  v-for="actionKey in actionButtonFieldKeys"
                  :key="actionKey"
                  class="button-container pb-1"
                >
                  <ActionButtonExecute
                    v-if="row.entityData && actionKey.split('#') && actionKey.split('#').length"
                    :data="getActionButtonField(actionKey, row._id)"
                    :form="row.entityData[actionKey.split('#')[0]]"
                    :is-view="false"
                    :isView="false"
                    :workflowDataId="row._id"
                    :label="getActionButtonField(actionKey, row._id).label"
                    :entity_type="currentEntity.entity_type"
                    :entityDataId="row._id"
                    :entityId="currentEntity._id"
                    :entityData="row"
                    :checkIsDisabled="false"
                    :selectedTableRows="[]"
                    :fieldsData="
                      getCurrentTemplateFields(actionKey.split('#')[0])
                    "
                    :selfTemplateId="actionKey.split('#')[0]"
                    templateName="templateName"
                  />
                </div>
              </div>
              <div :key="row._id" class="card" v-if="getParsedData(row._id)">
                <p v-html="getParsedData(row._id)"></p>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import ActionButtonExecute from "../../templates/formComponentsExecute/ActionButtonExecute.vue";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "EntityEmailView",
  data() {
    return {
      isCancelEditAll: false,
      myDate: "",
      result: [],
      searchTerm: "",
      searchData: [],
      searchQuery: "",
      isSearch: false,
      filteredData: [],
      loading: false,
      totalData: [],
      limit: 10,
      filterColumList: [],
      initialPage: true,
      selectedEmailTemp: "",
      total: 0,
      entityRecords: [],
      offset: 0,
      data: [],
      entityFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
      },
      isSelect: false,
      applied_quick_filters: [],
      currentPage: 1,
      pageSize: 10,
      options: [],
      isEditing: {
        title: false,
        description: false,
        field1: false,
        field2: false,
        field3: false,
      },
      editableFields: {
        title: "",
        description: "",
        field1: "",
        field2: "",
        field3: "",
      },
      editableFields_Types: {
        title: "text",
        description: "text",
        field1: "text",
        field2: "text",
        field3: "text",
      },
      UpdatedData: {},
      fetchData: [],
      updatedKeyValueData: {},
      quickUpdateIndex: -1,
      selectedEntityFields: [],

      entity_id: "",
      emailFields: [],
      htmlStrings: [
        "<div class='content'>HTML Content 1. This is a long content block that will overflow and require scrolling within the card. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum et ligula in nunc bibendum fringilla a eu lectus.</div>",
        "<div class='content'>HTML Content 2. This content is not too long.</div>",
        "<div class='content'>HTML Content 3. Here is some more text that will need scrolling if the card height is too small.</div>",
        "<div class='content'>HTML Content 4. Short content.</div>",
        "<div class='content'>HTML Content 5. Another block of text that might overflow depending on the height setting. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>",
      ],
      cardWidth: 30,
      selectedRecord: {},
      cardHeight: 300,
      selectedEmailTemplate: {},
      url_key: "",
      copyModal: false,
    };
  },
  components: {
    ActionButtonExecute,
  },
  props: [
    "entityId",
    "search_string",
    "showFields",
    "currentMenu",
    "isApplicationUserSideValid",
    "getSelfEntityFilters",
    "emailTemplates",
    "keyLabelData",
    "updateRow",
    "currentEntity",
    "quickUpdateRowData",
    "actionButtonFieldKeys",
    "parsedData",
    "isRelationalDataFetched",
    "allFieldsObj"
  ],
  mixins: [TemplateBuilderhelper, NavigationHelper, CustomDashboardConfig],
  mounted() {
    let limit =
      this.pageSizes && this.pageSizes[0]
        ? this.pageSizes[0]
        : this.getPageSize;
    this.pageSize = limit;
    this.refreshPage = true;
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.pageSize = parseInt(this.$route.query.pageSize) || 10;
    this.UpdatedData = this.quickUpdateRowData;
    this.entity_id = this.$route.params.entity_id || this.entityId;
    this.handleStyles();
    if (this.initialPage && this.currentPage == 1) {
      this.fetchEntitiesDataForTable();
      this.initialPage = false;
    }
  },
  created() {
    this.myDate = new Date();
  },
  methods: {
    handleDateChange(value) {
      this.editableFields.field3 = value;
    },
    isSelectField(fieldKey) {
      return this.currentEntity?.settings?.some(
        (setting) =>
          setting.label === this.getFieldKey(fieldKey) &&
          setting.type === "SELECT"
      );
    },
    onInputFocus() {
      this.isSearch = true;
    },
    getParsedData(dataId) {
      return (
        this.parsedData?.find((data) => data._id == dataId)?.ejsTemplate || ""
      );
    },
    getCurrentTemplateFields(tempId) {
      return this.currentEntity.templates.find((e) => e.template_id == tempId)
        ?.templateInfo?.sections[0]?.fields;
    },
    getActionButtonField(actionKey, dataId) {
      let field = this.allFieldsObj[actionKey];
      return {
        ...field,
        parent_entityDataId: dataId,
        parent_entityId: this.currentEntity._id,
        key: field.key.includes("#") ? field.key.split("#")[1] : field.key,
      };
    },
    performSearch() {
      this.searchData = [];
      if (this.isSearch) {
        const query = this.searchQuery.toLowerCase();
        this.filteredData = this.result.data.filter((row) => {
          return Object.keys(this.editableFields).some((field) => {
            const fieldValue = this.getFieldValue(field, row);
            if (fieldValue && fieldValue.toLowerCase().includes(query)) {
              if (!this.searchData.some((data) => data._id === row._id)) {
                this.searchData.push(row);
              }
              this.selectedRecord = this.searchData[0];
              return true;
            }
            return false;
          });
        });
      } else {
        this.filteredData = this.result.data;
        this.searchData = [];
      }
    },
    prepareEntityDataToShowInTable(data, currentEntity) {
      let allColumns = [];
      (currentEntity.templates || []).forEach((template) => {
        if (
          !template?.is_repeatable &&
          template?.templateInfo?.sections?.[0]?.fields
        ) {
          (
            this.applyCustomizationOnFields(
              template?.templateInfo?.sections[0]?.fields,
              template?.customization,
              template
            ) || []
          ).forEach((field) => {
            let key = field.key;
            allColumns.push({
              ...field,
              ...{
                template_id: template.template_id,
                id: key,
                key: template.template_id + "#" + field.key,
                checkCurrency: this.checkCurrency(field),
                checkIsEntityCount: this.checkIsEntityCount(field),
                getCurrencyFormet: this.getCurrencyFormet(field),
              },
            });
          });
        }
      });
      return data.map((row) => {
        allColumns.forEach((column) => {
          row[column.key] = this.getFieldValue(row, column, column);
          if (column?.checkCurrency) {
            row[column.key + "/checkCurrency"] = column.checkCurrency;
          }
          if (column?.heckIsEntityCount) {
            row[column.key + "/checkIsEntityCount"] = column.checkIsEntityCount;
          }
          if (column?.getCurrencyFormet) {
            row[column.key + "/getCurrencyFormet"] = column.getCurrencyFormet;
          }
        });
        return row;
      });
    },
    checkCurrency(field) {
      if (
        field?.show_count &&
        (field.show_count_type === "sum" ||
          field.show_count_type === "average") &&
        field.count_field
      ) {
        let [t, k] = field.count_field.split("#");
        let selectedEntity = this.getAllEntitiesInTemplate?.[field.entity_id];
        let selectTemplate = (selectedEntity?.templates || []).find(
          (e) => e.template_id == t
        );
        let isCurrencyField = (
          selectTemplate?.templateInfo?.sections?.[0]?.fields || []
        ).find((e) => e.key == k);
        if (isCurrencyField?.validations?.currency) {
          return isCurrencyField.validations;
        }
        return null;
      }
      return null;
    },
    checkIsEntityCount(field) {
      return field && field.show_count ? true : false;
    },
    getCurrencyFormet(field) {
      let codes = {
        USD: {
          currency: "USD",
          code: "en-US",
        },
        INR: {
          currency: "INR",
          code: "en-IN",
        },
        EUR: {
          currency: "EUR",
          code: "en-EU",
        },
        AUD: {
          currency: "AUD",
          code: "en-AU",
        },
        CAD: {
          currency: "CAD",
          code: "en-CA",
        },
      };
      if (field && field.validations && field.validations.currency) {
        return codes[field.validations.currency];
      } else if (field && field.currency_code && field.currency_code.currency) {
        return codes[field.currency_code.currency];
      } else {
        return {
          currency: "USD",
          code: "en-US",
        };
      }
    },
    async fetchEntitiesDataForTable(
      filters = [],
      pageChanged = false,
      reloading = false
    ) {
      if (!reloading) {
        this.loadingText = "Fetching data...";
        this.loading = true;
      }
      if (
        this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        filters = [...filters, ...this.getSelfEntityFilters];
      }
      let nestedRelationalEntityDatas = [];
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        nestedRelationalEntityDatas = await this.getNestedRelationshipDataIds();
      }
      if (!this.entityFiltersData && this.$route.query.filter) {
        this.resetEntityFiltersData();
      }
      filters = [...this.entityFiltersData.filters, ...filters];
      // this.exportFilters = filters;
      let checkDataTable = this.filterColumList.find(
        (e) => e.type == "DATA_TABLE"
      );
      if (this.entityFiltersData?.data_pageSize && !pageChanged) {
        this.pageSize = Number(this.entityFiltersData.data_pageSize);
      }
      let params = {
        entity_id: this.entity_id,
        template_fields_data: (this.currentEntity &&
        this.currentEntity.templates
          ? this.currentEntity.templates
          : []
        ).map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          )
        ),
        page: parseInt(this.getCurrentPage)
          ? parseInt(this.getCurrentPage)
          : this.currentPage,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search_string: this.search_string,
        data_table_field: checkDataTable,
      };
      if (this.$route?.query?.viewType == "HTML_CONTENT") {
        params.viewType = "HTML_CONTENT";
        params["email_template_id"] = this.getSelectedEmailTemplate();
      }
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        params.entity_data_ids = nestedRelationalEntityDatas;
        this.exportDataIds = nestedRelationalEntityDatas;
        if (!nestedRelationalEntityDatas.length) {
          this.data = [];
          this.total = 0;
          this.totalData = [];
          this.tableLoading = false;
          return;
        }
      }
      this.applied_filters = [];
      this.applied_filters = this.mapDateFilters(filters || []);
      if (this.entityFiltersData?.tableFieldGroupBy) {
        let subField = this.getField(this.entityFiltersData.tableFieldGroupBy);
        if (subField?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        params.group_by = this.entityFiltersData.tableFieldGroupBy;
        params.group_by_fields = this.entityFiltersData.tableFieldGroupByFields;
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          params.sub_group_by = this.entityFiltersData?.tableFieldSubGroupBy;
        } else {
          params.entity_variable_columns =
            this.entityFiltersData.filterEntityVariableColumns;
          if (
            this.getField(this.entityFiltersData.tableFieldGroupBy)?.entity_id
          ) {
            params.filter_variable_entity = this.getField(
              this.entityFiltersData.tableFieldGroupBy
            ).entity_id;
          }
        }
      }
      // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      let response = await postAPICall("POST", "/entities-data/entity/data", {
        ...params,
        limit: 10000,
      });

      this.result = response;
      this.exportFilters = params.filters;
      if (!this.currentEntity || this.currentEntity?._id !== this.entity_id) {
        this.currentEntity = response.selectedEntity;
        this.prepareKeyLabelsObject();
        await this.$store.commit(
          "entities/setEntityDataById",
          this.currentEntity,
          {
            root: true,
          }
        );
      }
      if (!reloading) {
        this.data = [];
      }
      if (
        this.entityFiltersData &&
        this.entityFiltersData.columns_settings &&
        this.entityFiltersData.columns_settings.length
      ) {
        this.filterColumList = [];
        this.filterColumList = this.entityFiltersData.columns_settings;
        //map with latest field type
        this.filterColumList = this.mapTableFieldsType(
          this.currentEntity,
          this.filterColumList
        );
      }
      if (response) {
        if (reloading) {
          return {
            data: this.prepareEntityDataToShowInTable(
              response.data,
              response.selectedEntity
            ),
            total: response.total ? response.total : 0,
          };
        } else {
          this.data = this.prepareEntityDataToShowInTable(
            response.data,
            response.selectedEntity
          );
        }
        this.templateData = response.selectedEntity.templates.map((e) => e);
        this.templateData.forEach((item) => {
          const sections = item.templateInfo.sections[0].fields;
          sections.forEach((field) => {
            if (field.options) {
              this.options[field.label] = field.options;
            }
          });
        });

        this.mapEntityDataWithUserInfo(this.data).then((e) => {
          this.entityUserInfoData = { ...e };
        });
        this.totalData = [...this.data];
        this.selectedRecord = this.totalData[0];
        this.total = response.total ? response.total : 0;
        this.custom_columns = [];
        if (this.entityFiltersData?.tableFieldGroupBy) {
          if (this.entityFiltersData?.tableFieldSubGroupBy) {
            let custom_columns = [];
            this.groupedData = response.data.map((data) => {
              if (data?.properties) {
                let label = "",
                  total = 0;
                (data.properties || []).forEach((el) => {
                  if (!label) {
                    label = el.entity_label;
                  }
                  total = total + el.count;
                  let value = el.sub_entity_label
                    ? el.sub_entity_label
                    : el.label;
                  let index = custom_columns.findIndex(
                    (e) => e.value == el.label
                  );
                  if (index == -1 && value) {
                    custom_columns.push({
                      value: el.label,
                      label: value,
                    });
                  }
                  (Object.keys(el) || []).forEach((key) => {
                    if (
                      ["entity_label", "label", "sub_entity_label"].indexOf(
                        key
                      ) == -1
                    ) {
                      data[el.label + "?" + key] = el[key];
                      data[value + "?" + key] = el[key];
                    }
                  });
                });

                data.entity_label = label;
                data.count = total;
              }
              return data;
            });
            if (
              this.entityFiltersData?.table_field_group_by_selected_options &&
              this.entityFiltersData.table_field_group_by_selected_options
                .length
            ) {
              custom_columns = custom_columns.filter(
                (e) =>
                  this.entityFiltersData.table_field_group_by_selected_options.indexOf(
                    e.value
                  ) != -1
              );
            }
            this.entityFiltersData.tableFieldGroupByFields
              .filter((e) => e.sub_group)
              .forEach((field) => {
                (custom_columns || []).forEach((col) => {
                  this.custom_columns.push({
                    label: col.label + "-" + field.name,
                    value: col.value + "?" + field.key,
                  });
                });
              });
          } else {
            this.groupedData = response.data;
            this.groupedData = this.groupedData.map((e) => {
              if (e && e.name && e.name.includes("#")) {
                e.name = e.name.split("#")[0];
              }
              return e;
            });
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getSelectedEmailTemplate() {
      if (this.showFields.selected_email_template) {
        this.selectedEmailTemp = this.showFields.selected_email_template;
        return this.selectedEmailTemp;
      } else if (this.emailTemplates?.length) {
        return this.allEmailTemplates[0]._id;
      }
    },
    async applyPagination(action) {
      if (action == "next") {
        this.currentPage++;
        this.offset = this.offset + this.pageSize;
      } else {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.offset = this.offset - this.pageSize;
        }
      }
      if (this.searchQuery && this.isSearch) {
        this.performSearch(this.searchData, this.searchQuery);
      }
      if ( this.paginatedData.length > 0) {
          this.selectedRecord = this.paginatedData[0];
        }
    },
    mapContactProfile(entityData) {
      return entityData.map((el) => {
        if (el?.contact_id?.[0]?.avatar) {
          el.avatar = el?.contact_id?.[0]?.avatar;
        }
        return el;
      });
    },
    resetEntityFiltersData() {
      this.entityFiltersData = {
        ...{
          filters: [],
          filter_name: "",
          isSaveFilter: false,
          query_type: "AND",
          quick_filters: [],
          columns_settings: [],
        },
      };
    },
    doubleClickUpdate(row) {
      const fieldsToUpdate = [
        "title",
        "description",
        "field1",
        "field2",
        "field3",
      ];
      fieldsToUpdate.forEach((field) => {
        const fieldValue = this.getFieldKey(field, row);
        if (this.keyLabelData && typeof this.keyLabelData === "object") {
          let fieldKey = null;
          for (const innerObject of Object.values(this.keyLabelData)) {
            if (innerObject && typeof innerObject === "object") {
              fieldKey = Object.keys(innerObject).find(
                (key) => innerObject[key] === fieldValue
              );
              if (fieldKey) break;
            }
          }
          if (fieldKey && this.editableFields[field] !== undefined) {
            let fieldUpdated = false;
            for (const templateKey of Object.keys(this.UpdatedData)) {
              const template = this.UpdatedData[templateKey];
              if (
                template &&
                Object.prototype.hasOwnProperty.call(template, fieldKey)
              ) {
                this.$set(
                  this.UpdatedData[templateKey],
                  fieldKey,
                  this.editableFields[field]
                );
                if (typeof this.UpdatedData[templateKey] !== "object") {
                  console.error(
                    "Template data is not an object:",
                    this.UpdatedData[templateKey]
                  );
                }
                fieldUpdated = true;
                break;
              }
            }
            if (!fieldUpdated) {
              const lastTemplateKey = Object.keys(this.UpdatedData).pop();
              if (lastTemplateKey) {
                this.$set(
                  this.UpdatedData[lastTemplateKey],
                  fieldKey,
                  this.editableFields[field]
                );
              } else {
                this.UpdatedData[`newTemplate`] = {
                  [fieldKey]: this.editableFields[field],
                };
              }
            }
          }
        } else {
          console.warn("keyLableData is not defined or is not an object");
        }
      });

      this.$emit("quickUpdateData", this.UpdatedData);
      this.$emit("updateRow", row);
    },
    cancelEditAll() {
      const fieldsToEdit = [
        "title",
        "description",
        "field1",
        "field2",
        "field3",
      ];

      fieldsToEdit.forEach((field) => {
        if (this.UpdatedData && this.UpdatedData[field]) {
          this.$set(this.editableFields, field, this.UpdatedData[field]);
        } else {
          this.$set(this.editableFields, field, "");
        }
        this.isEditing = {
          title: false,
          description: false,
          field1: false,
          field2: false,
          field3: false,
        };
        this.$set(this.isEditing, field, false);
        this.editableFields = {
          title: "",
          description: "",
          field1: "",
          field2: "",
          field3: "",
        };
      });
      this.isCancelEditAll = false;
    },

    cellDoubleClicked(row) {
      this.isCancelEditAll = true;
      this.isEditing = {
        title: true,
        description: true,
        field1: true,
        field2: true,
        field3: true,
      };
      for (const [, value] of Object.entries(
        this.currentEntity?.settings || {}
      )) {
        if (value.type === "AUTO_INCREMENT_NUMBER") {
          if (value.label === this.getFieldKey("title")) {
            this.isEditing.title = false;
          } else if (value.id === this.getFieldKey("description")) {
            this.isEditing.description = false;
          } else if (value.id === this.getFieldKey("field1")) {
            this.isEditing.field1 = false;
          } else if (value.id === this.getFieldKey("field2")) {
            this.isEditing.field2 = false;
          } else if (value.id === this.getFieldKey("field3")) {
            this.isEditing.field3 = false;
          }
        }
        if (value.type === "DATE_TIME") {
          if (value.label === this.getFieldKey("title")) {
            this.editableFields.title = this.myDate;
            this.editableFields_Types.title = "DATE_TIME";
          } else if (value.label === this.getFieldKey("description")) {
            this.editableFields.description = this.myDate;
            this.editableFields_Types.description = "DATE_TIME";
          } else if (value.label === this.getFieldKey("field1")) {
            this.editableFields.field1 = this.myDate;
            this.editableFields_Types.field1 = "DATE_TIME";
          } else if (value.label === this.getFieldKey("field2")) {
            this.editableFields.field2 = this.myDate;
            this.editableFields_Types.field2 = "DATE_TIME";
          } else if (value.label === this.getFieldKey("field3")) {
            this.editableFields.field3 = this.myDate;
            this.editableFields_Types.field3 = "DATE_TIME";
          }
        }
        if (value.type === "SELECT") {
          if (value.label === this.getFieldKey("title")) {
            this.editableFields.title = this.options[value.label];
            this.editableFields_Types.title = "SELECT";
          }
          if (value.label === this.getFieldKey("description")) {
            this.editableFields.description = this.options[value.label];
            this.editableFields_Types.description = "SELECT";
          }
          if (value.label === this.getFieldKey("field1")) {
            this.editableFields.field1 = this.options[value.label];
            this.editableFields_Types.field1 = "SELECT";
          }
          if (value.label === this.getFieldKey("field2")) {
            this.editableFields.field2 = this.options[value.label];
            this.editableFields_Types.field2 = "SELECT";
          }
          if (value.label === this.getFieldKey("field3")) {
            this.editableFields.field3 = this.options[value.label];
            this.editableFields_Types.field3 = "SELECT";
          }
        }
      }
      this.quickUpdateIndex = this.data.findIndex(
        (e) => e._id.toString() == row._id.toString()
      );
      this.UpdatedData = null;
      this.UpdatedData = JSON.parse(JSON.stringify(row.entityData));
      if (
        this.currentEntity?.templates.length !==
        Object.keys(row.entityData).length
      ) {
        this.currentEntity?.templates.map((tmp) => {
          if (!Object.keys(this.UpdatedData).includes(tmp.template_id)) {
            let emptyTemplateDataObj = {};
            tmp.templateInfo.sections[0].fields.map((fd) => {
              emptyTemplateDataObj[fd.key] = "";
            });
            this.UpdatedData[tmp.template_id] = emptyTemplateDataObj;
          }
        });
      }
      this.editableFields = {
        title: this.getFieldValue("title", row),
        description: this.getFieldValue("description", row),
        field1: this.getFieldValue("field1", row),
        field2: this.getFieldValue("field2", row),
        field3: this.getFieldValue("field3", row),
      };
    },
    hasHash(value) {
      return value.includes("#");
    },
    async viewWorkUserData(row, action = "View") {
      if (row._id) {
        let path = "";

        if (action === "View" || action === "Edit") {
          path = await this.determinePath(action);
        } else {
          this.performAnotherAction(action, row);
          return;
        }
        if (path) {
          let query = this.getNavigationQuery(this.$route.query);
          query = {
            ...query,
            dataId: row._id,
            filter: this.currentFilter,
            menu_id: this.$route.params.menuId,
          };
          if (action === "Edit" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.pageSize = this.$route.query.pageSize || 10;
            query.type = "AUfYWb7SC6U=";
          }
          if (action === "View" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.type = "AUfYWb7SC6U=";
          }
          this.$router.push({
            path: path,
            query: query,
          });
        }
      }
    },
    async performAnotherAction(action, row) {
      const matchingAction = this.customization?.actions.find(
        (item) => item.label === action
      );
      if (matchingAction) {
        this.quickActionDummyField = {
          label: matchingAction.label,
          parent_entityId: this.currentEntity._id,
          parent_entityDataId: row._id,
          action_button_type: "QUICK_ACTION",
          actions: [
            {
              action_button_action_type: "ESTABLISH_RELATIONSHIP",
              relational_entity_one: matchingAction.relationship,
              action_button_relationship_type:
                this.quickActionButtonType[matchingAction.type],
            },
          ],
          type: matchingAction.type,
          execute: true,
        };
        this.quickActionModal = true;
      }
    },
    async determinePath(action) {
      let path = "";
      if (action === "View") {
        if (this.isApplicationUserSideValid) {
          path = `/ap/entity-view/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/view/${this.entity_id}`
              : `/entity/view/${this.entity_id}`;
        }
      } else if (action === "Edit") {
        if (this.isApplicationUserSideValid) {
          path = `/ap/entity-execute/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`
              : `/entity/edit/${this.entity_id}`;
        }
      }
      return path;
    },
    getFieldKey(field) {
      if (!this.keyLabelData || !Object.keys(this.keyLabelData).length) {
        console.warn("keyLabelData is undefined or empty");
        return null;
      }
      let firstObjectKey = Object.keys(this.keyLabelData)[0];
      if (this.showFields[field]) {
        let [, key] = this.showFields[field].split("#");
        if (this.keyLabelData[firstObjectKey][key]) {
          return this.keyLabelData[firstObjectKey][key];
        }
      }
      return null;
    },
    getFieldValue(field, data) {
      if (!data || !data.entityData) {
        return "";
      }
      let firstObject = Object.keys(data.entityData)[0];
      if (!firstObject) {
        return "";
      }
      if (
        "date_time" in (data.entityData[firstObject] || {}) &&
        this.showFields[field]?.split("#")[1] === "date_time" &&
        data.entityData[firstObject]["date_time"]
      ) {
        const formattedDate = this.formatDate(
          data.entityData[firstObject]["date_time"]
        );
        return formattedDate;
      }
      if (field === "action_group_button") {
        let quickActions = [];
        if (this.showFields[field]?.length > 0) {
          quickActions = this.showFields[field].filter(
            (item) => !item.includes("#")
          );
          if (quickActions.length > 0) {
            return quickActions.map((item) => item);
          }
          return "";
        }
      } else if (
        data.entityData &&
        this.showFields[field] &&
        this.showFields[field].includes("#")
      ) {
        let [temp, key] = this.showFields[field]?.split("#") || [];
        if (temp && key) {
          if (data.entityData[temp] && data.entityData[temp][key]) {
            return (
              data.entityData[temp][key + "/name"] || data.entityData[temp][key]
            );
          }
        }
        return "";
      } else {
        if (this.currentEntity === "INDIVIDUAL") {
          if (
            field === "profile" &&
            data.avatar &&
            this.showFields[field] === "defaultpic"
          ) {
            return data.avatar;
          }
        }
        return "";
      }
    },
    handleStyles() {
      if (this.showFields?.columnsPerRow) {
        switch (this.showFields.columnsPerRow) {
          case 1:
            this.cardWidth = 100;
            this.cardHeight = 600;
            break;
          case 2:
            this.cardWidth = 45;
            this.cardHeight = 400;
            break;
          case 3:
            this.cardWidth = 32;
            this.cardHeight = 300;
            break;
        }
      }
    },
    selectedCard(dt) {
      this.selectedRecord = dt;
    },
    checkEmailTemplateExists() {
      return this.selectedEmailTemplate;
    },
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "getEntityShowFields",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      const dataSource =
        this.isSearch && this.searchQuery ? this.searchData : this.data;
      const filteredData = dataSource.slice(start, end);
      return filteredData;
    },
    searchFilterData() {
      if (this.searchQuery !== "") {
        if (this.selectedRecord) {
          return this.searchData.filter(
            (row) => row._id === this.selectedRecord._id
          );
        } else {
          return this.searchData;
        }
      }
      return [];
    },

    middleDivFilterData() {
      if (this.selectedRecord) {
        return this.data.filter((row) => row._id === this.selectedRecord._id);
      }
      return this.data;
    },
  },
  watch: {
    paginatedData() {
      if (this.searchData.length > 0) {
        this.selectedRecord = this.searchData[0];
        return this.searchData;
      }
    },
    showFields: {
      handler: function () {
        if (
          JSON.stringify(this.showFields) != JSON.stringify(this.showFields)
        ) {
          this.showFields = { ...this.showFields };
        }
      },
      deep: true,
    },
    search_string: async function () {
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
    },
    "showFields.columnsPerRow": {
      async handler() {
        await this.handleStyles();
      },
      deep: true,
    },
    "showFields.selected_email_template": {
      async handler() {
        this.selectedEmailTemplate = this.showFields.selected_email_template;
      },
      deep: true,
    },
    isRelationalDataFetched: {
      handler: function () {
        // console.log("isRelationalDataFetched", this.isRelationalDataFetched);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.bold-key {
  font-weight: 700;
}
.edit-input {
  width: 80%;
  margin-right: 5px;
  padding: 8px;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.edit-icons {
  display: inline-block;
}

.tick-icon {
  color: green;
  cursor: pointer;
  margin-right: 5px;
}

.cross-icon {
  color: red;
  cursor: pointer;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 16px;
  width: 46.5vw;
  overflow-x: hidden;
}
.card-body {
  border-radius: 10px;
  background: #ffff;
  overflow-wrap: break-word;
}
.card-profile {
  margin-left: 60px;
  margin-top: 10px;
  /* margin-right:60px ; */
}
.content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.size-selector {
  margin-bottom: 16px;
}

.size-selector label {
  font-weight: bold;
  margin-right: 8px;
}

.size-selector input {
  margin-right: 8px;
}
.entity-data-card {
  background: #ffffff;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 10px;
  min-height: 200px;
  border: 1px solid slategray;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .card-container {
    justify-content: center;
  }

  .card {
    flex: 0 1 100%;
  }
}

@media (max-width: 1200px) {
  .card {
    flex: 0 1 48%;
  }
}

@media (min-width: 1201px) {
  .card {
    flex: 0 1 30%;
  }
}

.card-summary {
  max-height: 10vh;
}

.summary {
  min-width: 170px;
  min-height: 10vh;
  overflow-y: hidden;
  background-color: beige;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.card-details {
  max-height: 30vh;
  width: 15vw;
}
.edit-icons {
  align-content: center;
  align-items: center;
  float: right;
  margin: 5px;
}
.details {
  max-height: 40vh;
  overflow-y: hidden;
  background-color: ffffff;
  border: 1px slategray solid;
  border-radius: 5px;
  margin-bottom: 5px;
}
.button-container {
  display: flex;
  justify-content: end;
}
</style>
